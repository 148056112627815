import Proxy from './Proxy';

class EmailMasterTemplateProxy extends Proxy {
    constructor(parameters = {}) {
        super('/email-master-template', parameters);
    }
}

export default EmailMasterTemplateProxy;

