import Proxy from './Proxy';

class ParticipantUploadProxy extends Proxy {
    constructor(parameters = {}) {
        super('/participant-upload', parameters);
    }

    all({ participantId = null, type = null } = {}) {

        if (participantId && type) {
            return this.submit('get', `/?participant=${participantId}&type=${type}`);
        }
        if (type) {
            return this.submit('get', `/?type=${type}`);
        }
        if (participantId) {
            return this.submit('get', `/?participant=${participantId}`);
        }
        return this.submit('get', '/');
    }

    send(id, formData) {
        return this.submit('post', `/${id}/send`, formData);
    }

    email(id, formData) {
        return this.submit('get', `/${id}/email`, formData);
    }
    sendFinalReport(id, formData) {
        return this.submit('post', `/${id}/send-final-report`, formData);
    }
    sendLearnContract(id, formData) {
        return this.submit('post', `/${id}/send-learn-contract`, formData);
    }
    sendBescheinigung(id, formData) {
        return this.submit('post', `/${id}/send-certificate`, formData);
    }
}

export default ParticipantUploadProxy;
