import Proxy from './Proxy';

class ParticipantProxy extends Proxy {
    constructor(parameters = {}) {
        super('/participant', parameters);
    }

    all({ courseType = '', status = '', ravConsultantId = null, courseId = null, waiting = false, active = false } = {}) {
        let courseTypeQuery = '';
        if (courseType !== '') {
            courseType = courseType.toUpperCase();
            courseTypeQuery = `&courseType=${courseType}`;
        }
        if (status !== '') {
            return this.submit('get', `/?status=${status}${courseTypeQuery}`);
        }
        if (ravConsultantId) {
            return this.submit('get', `/?ravConsultant=${ravConsultantId}${courseTypeQuery}`);
        }
        if (waiting && courseId) {
            return this.submit('get', `/?waitingCourse=${courseId}${courseTypeQuery}`);
        }
        if (active && courseId) {
            return this.submit('get', `/?activeCourse=${courseId}${courseTypeQuery}`);
        }
        if (courseId) {
            return this.submit('get', `/?course=${courseId}${courseTypeQuery}`);
        }
        if (courseType !== '') {
            return this.submit('get', `/?courseType=${courseType}`);
        }
        return this.submit('get', '/');
    }

    upload(id, formData) {
        return this.submit('post', `/${id}/upload`, formData);
    }

    export(exportType) {
        return this.submit('post', `/export/${exportType}`, null, null, {}, 'blob');
    }

    downloadCertificate(ids, date, correction, language) {
        return this.submit('post', `/certificate/${date}/${ids.join(',')}/${correction ? '1' : '0'}?language=${language}`, null, null, {}, 'blob');
    }

    sendCertificate(ids, date, correction, language) {
        return this.submit('post', `/send-certificate/${date}/${ids.join(',')}/${correction ? '1' : '0'}?language=${language}`, null, null, {}, 'blob');
    }

    generateCourseIdentityDocuments(ids, action) {
        return this.submit('post', `/course-identities/${ids.join(',')}/${action}`, null, null, {}, 'blob');
    }

    generateMultipleFinalReports(ids, date, correction) {
        return this.submit('post', `/final_reports/${ids.join(',')}`, null, null, {}, 'blob');
    }

    generateGoalDocument(id) {
        return this.submit('post', `/${id}/generate-file/goal`);
    }

    generateFinalReportDocument(id) {
        return this.submit('post', `/${id}/generate-file/final`);
    }

    generateCourseIdentityDocument(id) {
        return this.submit('post', `/${id}/generate-file/course_identity`);
    }

    sendFirstInterviewEmail(id, formData) {
        return this.submit('post', `/${id}/first-interview-send`, formData);
    }

    firstInterviewEmail(id, formData) {
        return this.submit('get', `/${id}/first-interview-email`, formData);
    }
}

export default ParticipantProxy;
