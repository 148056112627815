export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {
            return data;
        },
        prepareFormData(data) {
            return {
                email_master_template: {
                    subject: data.subject,
                    fromEmail: data.from_email,
                    body: data.body,
                    emailType: data.email_type,
                },
            };
        },
    },
};
